import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'
import axios from 'axios'

const token = process.env.VUE_APP_DART_CONNECT_TOKEN
const url = process.env.VUE_APP_DART_CONNECT_URL

export class DartConnect extends APIBaseFunctions {
  static DartConnectDataService = class {
    private static DartConnectDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getDartConnectTournaments (extraParameter = '') {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.get(`${url}/tournaments/?sort=-start_date`, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async getDartConnectTournamentEvents (tournamentId: string, extraParameter = '') {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.get(`${url}/tournaments/${tournamentId}/events`, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async getDartConnectTournamentsEventsMatchResults (tournamentId: string, eventId: string) {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.get(`${url}/tournaments/${tournamentId}/events/${eventId}/matches`, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async getTournamentPlayers (tournamentId: any) {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.get(`${url}/tournaments/${tournamentId}/players`, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async setPlayerToTournament (tournamentId: any, players: any) {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(`${url}/tournaments/${tournamentId}/players`, players, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async getPlayersOfTournament (tournamentId: any) {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.get(`${url}/tournaments/${tournamentId}/players`, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }

    static async setPlayerToTournamentEvent (eventId: any, DCplayer: any) {
      const headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(`${url}/event-registrations/player/${eventId}`, DCplayer, { headers })
        return response.data
      } catch (error) {
        return error
      }
    }
  }
}
export default new DartConnect()
